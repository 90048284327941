import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  CareCenterViewModel,
  PatientListItemOwner,
  PatientListItemStatus,
  PatientListItemType,
  ResidentDepartmentModel,
} from "../apiClient";
import { GlobalContext } from "../context/GlobalContext";
import { FILTER_KEYS, USER_ROLE_NAMES, USER_ROLES } from "../enums/enums";

export type FilterOptionType = {
  header: string;
  name: string;
  label: string;
  value: string | number;
  children?: FilterOptionType[];
};

type usePatientListFiltersType = {
  filters: FilterOptionType[][];
  getFilterValue: Function;
  getFilterValues: Function;
  isFilterSelected: Function;
  areAllSelected: Function;
  partiallySelected: Function;
  toggleFilterSelected: Function;
  toggleAllSelected: Function;
  clearFilter: Function;
};

export const getDefaultFilters = (clinicRole: string | undefined) => {
  const searchParams = new URLSearchParams();
  searchParams.append(
    FILTER_KEYS.STATUS,
    PatientListItemStatus.Open.toString(),
  );
  if (clinicRole) {
    searchParams.append(FILTER_KEYS.OWNER, PatientListItemOwner.Me.toString());
  }
  return searchParams.toString();
};

const getUniqueRoles = (
  careCenterOrResidentDepartment:
    | ResidentDepartmentModel[]
    | CareCenterViewModel[],
) => {
  return [
    ...new Map(
      careCenterOrResidentDepartment.flatMap((cc) =>
        cc.userRoles.map((r) => [r.role, r]),
      ),
    ).values(),
  ];
};

const usePatientListFilters = (): usePatientListFiltersType => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { tags, residentDepartments, careCenters, contactReasons, clinicRole } =
    useContext(GlobalContext);

  const residentDepartmentRoles = getUniqueRoles(residentDepartments);

  const careCenterRoles = getUniqueRoles(careCenters);

  const roles = [...residentDepartmentRoles, ...careCenterRoles].filter(
    (value, index, self) =>
      // the first occurrence of the role in the array is this one
      index ===
      self.findIndex(
        (t) => t.role === value.role && t.roleName === value.roleName,
      ),
  );

  const { showCareCenter } = useContext(GlobalContext);

  const filters: FilterOptionType[][] = [
    ...(showCareCenter
      ? [
          careCenters
            .filter((c) => c.hasPatients)
            .map(({ id, name }) => ({
              header: "Vårdcentral",
              name: FILTER_KEYS.CARE_CENTER_ID,
              value: id,
              label: name,
            })),
        ]
      : []),
    residentDepartments
      .filter((c) => c.hasPatients)
      .map(({ id, name, units = [] }) => ({
        header: "Boende",
        name: FILTER_KEYS.RESIDENT_DEPARTMENT_ID,
        value: id,
        label: name,
        children: units.map(({ unitId, name }) => ({
          header: "Avdelning",
          name: FILTER_KEYS.UNIT_ID,
          value: unitId,
          label: name,
        })),
      })),
    [
      {
        header: "Berörd roll",
        name: FILTER_KEYS.OWNER,
        value: PatientListItemOwner.Me,
        label: `Jag${
          clinicRole
            ? `, ${USER_ROLE_NAMES[USER_ROLES[clinicRole]].toLowerCase()}`
            : ""
        }`,
      },
      {
        header: "Berörd roll",
        name: FILTER_KEYS.OWNER,
        value: PatientListItemOwner.Others,
        label: "Övriga",
        children: roles.map((role) => ({
          header: "Berörd roll",
          name: FILTER_KEYS.ROLES,
          value: role.role,
          label:
            clinicRole && USER_ROLES[clinicRole] === role.role
              ? `${role.roleName}, övriga`
              : role.roleName,
        })),
      },
    ],
    [
      {
        header: "Status",
        name: FILTER_KEYS.STATUS,
        value: PatientListItemStatus.Open,
        label: "Öppna",
      },
      {
        header: "Status",
        name: FILTER_KEYS.STATUS,
        value: PatientListItemStatus.Completed,
        label: "Avslutade",
      },
    ],
    [
      {
        header: "Typ",
        name: FILTER_KEYS.TYPE,
        value: PatientListItemType.Issue,
        label: "Ärenden",
        children: contactReasons.map((c) => ({
          name: FILTER_KEYS.CONTACT_REASON,
          header: "Kontaktorsak",
          label: c.name,
          value: c.id,
        })),
      },
      {
        header: "Typ",
        name: FILTER_KEYS.TYPE,
        value: PatientListItemType.Reminder,
        label: "Påminnelser",
      },
      {
        header: "Typ",
        name: FILTER_KEYS.TYPE,
        value: PatientListItemType.Note,
        label: "Privata anteckningar",
      },
    ],
    tags
      .filter((t) => t.showInFilter)
      .map(({ name }) => ({
        header: "Taggar",
        name: FILTER_KEYS.TAGS,
        value: name,
        label: name,
      })),
  ];

  const getFilterValue = (name: string) => searchParams.get(name);
  const getFilterValues = (name: string) => searchParams.getAll(name);

  const isFilterSelected = (name: string, value: string | number) => {
    const currentValues = searchParams.getAll(name);
    return currentValues.includes(String(value));
  };

  const areAllSelected = (
    name: string,
    options: { value: string | number }[],
  ): boolean => {
    const selected = getFilterValues(name);
    return options.length != 0 && options.every((option) => selected.includes(String(option.value)));
  };

  const partiallySelected = (
      name: string,
      options: { value: string | number }[],
  ): boolean => {
    const selected = getFilterValues(name);
    const selectedOptions = options.filter((option) => selected.includes(String(option.value)));
    return (options.length > selectedOptions.length) && selectedOptions.length > 0;
  };
  
  const deleteByNameAndValue = (name: string, value: string | number) => {
    const newValues = getFilterValues(name).filter((v) => v !== String(value));
    searchParams.delete(name);
    newValues.forEach((newValue) => searchParams.append(name, newValue));
  };

  const toggleFilterSelected = (
    name: string,
    value: string | number,
    children: { name: string; value: string | number }[] = [],
  ) => {
    if (isFilterSelected(name, value)) {
      children.forEach(({ name: childName, value: childValue }) =>
          deleteByNameAndValue(childName, childValue)
      );
      deleteByNameAndValue(name, value);
    } else {
      searchParams.append(name, value.toString());
      children.forEach(({ name: childName, value: childValue }) =>
          deleteByNameAndValue(childName, childValue.toString())
      );
    }
    searchParams.delete(FILTER_KEYS.PAGE);
    setSearchParams(searchParams);
  };

  const toggleAllSelected = (name: string, options: FilterOptionType[]) => {
    const allSelected = areAllSelected(name, options);
    searchParams.delete(name);
    options.forEach((o) => {
      if (o.children && o.children.length > 0) {
        searchParams.delete(o.children[0].name);
      }
    });
    searchParams.delete(FILTER_KEYS.PAGE);
    if (!allSelected) {
      options.forEach(({ value }) =>
        searchParams.append(name, value.toString()),
      );
    }
    setSearchParams(searchParams);
  };

  const clearFilter = (name: string) => {
    searchParams.delete(name);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    window.sessionStorage.setItem("patientListParams", searchParams.toString());
  }, [searchParams]);

  return {
    filters,
    getFilterValue,
    getFilterValues,
    isFilterSelected,
    areAllSelected,
    partiallySelected,
    toggleFilterSelected,
    toggleAllSelected,
    clearFilter,
  };
};

export default usePatientListFilters;
