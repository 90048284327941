import { PrimaryButton, SecondaryButton } from "@collabodoc/component-library";
import { faClose, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useContext } from "react";
import styled from "styled-components";
import { device, size } from "../../Content/Style/devices";
import usePatientListFilters, {
  FilterOptionType,
} from "../../hooks/usePatientListFilters";
import { GlobalContext } from "../../context/GlobalContext";

type PageHeaderProps = {
  setShowCreatePatientModal: Function;
  setShowSideMenu: Function;
  showSideMenu: boolean;
};
export const PageHeader = ({
  setShowCreatePatientModal,
  setShowSideMenu,
  showSideMenu,
}: PageHeaderProps) => {
  const { residentDepartments } = useContext(GlobalContext);
  return (
    <Header>
      <UpperHeader>
        <HeaderGroup>
          <StyledSecondaryButton onClick={() => setShowSideMenu(!showSideMenu)}>
            <Icon icon={showSideMenu ? faClose : faFilter} />
            {showSideMenu ? "Stäng filtrering" : "Filtrera"}
          </StyledSecondaryButton>
          {residentDepartments.some((r) => r.userCanEditPatients) && (
            <StyledPrimaryButton
              onClick={() => setShowCreatePatientModal(true)}
            >
              Lägg till patient
            </StyledPrimaryButton>
          )}
        </HeaderGroup>
      </UpperHeader>
      <ResetFilterButtons />
    </Header>
  );
};

const ResetFilterButtons = () => {
  const { filters } = usePatientListFilters();
  const { isFilterSelected, toggleFilterSelected, partiallySelected } = usePatientListFilters();
  return (
    <div>
      {filters.map((options = []) =>
        options
          .map(({ name, value, label, header, children = [] }) => (
              <Fragment key={`${name}-${value}`}>
                {isFilterSelected(name, value) &&
              <ResetFilterButton
                header={header}
                name={name}
                value={value}
                label={label}
                children={children}
                toggleFilterSelected={toggleFilterSelected}
              />}
              {partiallySelected(children[0]?.name, children) && children
                .filter(({ name, value }) => isFilterSelected(name, value))
                .map(({ name, value, label, header }) => (
                  <Fragment key={`${name}-${value}`}>
                    <ResetFilterButton
                      header={header}
                      name={name}
                      value={value}
                      label={label}
                      toggleFilterSelected={toggleFilterSelected}
                    />
                  </Fragment>
                ))}
            </Fragment>
          )),
      )}
    </div>
  );
};

type ResetFilterButtonProps = {
  header: string;
  name: string;
  label: string;
  value: string | number;
  childName?: string;
  children?: FilterOptionType[];
  toggleFilterSelected: Function;
};
const ResetFilterButton = ({
  header,
  name,
  label,
  value,
  children = [],
  toggleFilterSelected,
}: ResetFilterButtonProps) => (
  <FilterButton
    onClick={() =>
      toggleFilterSelected(
        name,
        value,
        children.map((child) => ({
          name: child.name,
          value: child.value,
        })),
      )
    }
  >
    <FontAwesomeIcon icon={faClose} />
    {`${header}: ${label}`}
  </FilterButton>
);

const FilterButton = styled.button`
  border-radius: 5px;
  background-color: white;
  border: 1px solid #757575;
  color: #757575;
  margin: 0 5px 5px 0;
  padding: 2px 7px 2px 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: small;

  :hover {
    background-color: ${({ theme }) => theme.button.secondary.hover};
  }

  > svg {
    margin-right: 4px;
  }
`;

const Header = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const UpperHeader = styled.section`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  max-height: 38px;

  @media ${device.tablet} {
    flex-direction: column;
    max-height: 85px;
  }
`;

const HeaderGroup = styled.div`
  display: flex;
  gap: 12px;

  @media ${device.tablet} {
    justify-content: space-between;
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  @media (min-width: ${size.mobileL}) {
    min-width: 155px;
  }

  @media ${device.tablet} {
    flex: 1;
  }
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  @media (min-width: ${size.mobileL}) {
    min-width: 155px;
  }

  @media ${device.tablet} {
    flex: 1;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 4px;
`;
