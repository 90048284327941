import {GlobalContext} from "../context/GlobalContext";
import {useContext} from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {HubConnectionState} from "@microsoft/signalr";

const SignalRAlert = () => {
    const {hubConnectionState} = useContext(GlobalContext);
    const navigate = useNavigate()

    return (
        <>
            {(hubConnectionState === HubConnectionState.Disconnected) &&
                <AlertBox id="signalRAlert">
                    Just nu uppdateras inte sidan automatiskt. <StyledLink onClick={() => navigate(0)}>Ladda
                    om sidan</StyledLink> för
                    att uppdatera.
                </AlertBox>
            }
        </>
    )
};

const AlertBox = styled.div`
    margin-top: 2px;
    padding: 12px;
    background-color: #fcf8e3;
`

const StyledLink = styled.button`
    cursor: pointer;
    display: inline;
    border: none; // Remove default button styles
    background-color: transparent; // Remove default button styles
    padding: 0; // Remove default button styles
    font-family: inherit; // Inherit font styles from the surrounding text
    font-size: inherit; // Inherit font size from the surrounding text
    &:hover {
        text-decoration: underline;
    }
`

export default SignalRAlert;