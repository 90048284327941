import { GlobalContextProvider } from "./context/GlobalContext";
import NavMenu from "./components/NavMenu";
import { Outlet } from "react-router-dom";
import { PatientListContextProvider } from "./context/PatientListContext";
import SignalRAlert from "./components/SignalRAlert";

const Root = () => {
  return (
    <GlobalContextProvider>
      <PatientListContextProvider>
        <NavMenu />
          <SignalRAlert />
        <Outlet />
      </PatientListContextProvider>
    </GlobalContextProvider>
  );
};

export default Root;
